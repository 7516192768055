import * as React from "react";
import "../../styles.css";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import { Link } from "react-router-dom";
// import Ann from "../img/ann.jpg";
// import Blog from "../img/blog.gif";
// import ReactiveTriadsLogo from "../img/reactive-triads-bodywork-logo.jpg";

const homeBackground = {
  backgroundColor: "#37659D",
  paddingBottom: "10px",
  // justifyContent: "center",
};
const homeLinks = {
  textAlign: "center",
  color: "#FFFFFF",
  textDecoration: "none",
};

function Services() {
  return (
    <>
      <div style={homeBackground}>
        <div style={homeLinks}>
          <h1>Services: Under Development</h1>
          <p>Lorem Ipsum</p>
        </div>
      </div>
    </>
  );
}
export default Services;
