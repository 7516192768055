import * as React from "react";
import Cardflip from "../cardFlip/cardFlip";
import beLogo from "../img/beLogo.png";

const homeLinks = {
  textAlign: "center",
  color: "#000",
  textDecoration: "none",
};

const beLogoStyle = {
  maxHeight: "8.2vh",
  height: "100%",
  cursor: "pointer",
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "10vh",
};

function Home() {
  return (
    <>
      <div style={{ backgroundColor: "#fff", padding: "2rem" }}>
        <div className="container" style={homeLinks}>
          <div style={containerStyle}>
            <img src={beLogo} style={beLogoStyle} alt="beLogo" />
          </div>
        </div>
        <Cardflip />
      </div>
    </>
  );
}

export default Home;
