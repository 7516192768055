// Templated
import "./styles.css";
// Templated

//Router
import * as React from "react";
import { Link, Outlet } from "react-router-dom";
// Router

import homeSplash3 from "./components/img/homeSplash3.avif";
import beLogo from "./components/img/beLogo.png";
// import Header from "./components/header";
import Footer from "./components/footer";

const beLogoStyle = {
  maxHeight: "8.2vh",
  height: "100%",
  cursor: "pointer",
  marginLeft: "3rem",
  marginRight: "15rem",
  marginTop: "3rem",
};

const navBtnStyle = {
  padding: "1rem",
  marginRight: "1rem",
};

function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  return (
    <div>
      {/* <Header /> */}
      <nav
        className="jumbotron section NavbarItems"
        style={{
          backgroundImage: `url(${homeSplash3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "50vh",
        }}
      >
           <div className="left-container">
          <a href="/">
            <img src={beLogo} style={beLogoStyle} alt="beLogo" />
          </a>
        </div>
        <div className="right-container">
          <button
            className="btn btn-success btn-lg"
            style={navBtnStyle}
            onClick={toggleMenu}
          >
            Menu
          </button>
          {isMenuOpen && (
            <div>
              <Link to="/">
                <button className="btn btn-success btn-lg" style={navBtnStyle}>
                  Home
                </button>
              </Link>

              <Link to="/services">
                <button className="btn btn-success btn-lg" style={navBtnStyle}>
                  Services
                </button>
              </Link>
              <Link to="/contact">
                <button className="btn btn-success btn-lg" style={navBtnStyle}>
                  Contact
                </button>
              </Link>
              {/* <a href="tel:(914) 450.3792">
                <button className="btn btn-success btn-lg" style={navBtnStyle}>
                  Call
                </button>
              </a> */}
              <a href="mailto:info@benvironmentalconsulting.com">
                <button className="btn btn-success btn-lg" style={navBtnStyle}>
                  Email
                </button>
              </a>
            </div>
          )}
        </div>
      </nav>
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
