import React from "react";
// import React, { useState, useEffect, useRef } from "react";

function Aboutflip() {
  return (
    <div className="card" id="about">
      <div className="content">
        <div className="front">About Us</div>
        <div className="back">
          <p>Locally Owned & Operated</p>
          <p>Licensed & Certified</p>
          <p>Free Estimates</p>
          <p>
            <a href="mailto:info@benvironmentalconsulting.com" style={{ color: "white" }}>
            info@benvironmentalconsulting.com
            </a>
            < br/>
            FOR A QUOTE!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aboutflip;
