// import { Button } from "bootstrap";
import * as React from "react";

import beLogo from "../img/beLogo.png";
// import na from '../img/na.jpg'
import pineContact from "../img/pineContact.webp";

function Contact() {
  const contactStyle = {
    marginLeft: "1.25vw",
    marginRight: "1.25vw",
    padding: "5rem",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };
  const contactLogo = {
    // height: '55vh',
    width: "15vw",
    // borderRadius: "5px",
    // boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
    padding: "1rem"
  };
  const contactTitle = {
    textAlign: "center",
    padding: "3rem",
    marginTop: "0rem",
    color: "#000",
    // color: 'ivory',
  };

  const background = {
    backgroundImage: `url(${pineContact})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  };

  return (
    <>
      <div style={background}>
        <h1 style={contactTitle}>Founder</h1>
        <div className="row" style={contactStyle}>
          <div className="col-md-4">
            <img src={beLogo} style={contactLogo} alt="beLogo" />
            <br />
            <a href="mailto:info@benvironmentalconsulting.com">
              <button className="btn btn-lg" id="contactNow">
                info@benvironmentalconsulting.com
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
