import React from "react";
import ServicesFlip from "./servicesFlip.jsx";
import AboutFlip from "./aboutFlip.jsx";

function Cardflip() {
  return (
    <>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-3" style={{ margin: "auto" }}>
            <AboutFlip />
          </div>
          <div className="col-lg-3" style={{ margin: "auto" }}>
            <ServicesFlip />
          </div>
        </div>
      </div>
    </>
  );
}

export default Cardflip;
