import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMailForward } from "@fortawesome/free-solid-svg-icons";

const footer = {
  background: "#198754",
  display: "flex",
  // background: "#5E87A1",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: "1rem",
};

export default function Footer() {
  return (
    <>
      <div style={footer}>
      <p style={{ color: "#fff", marginBottom: "1rem" }}>
        Portland OR 97201 |{" "}
        <Link to="mailto:info@benvironmentalconsulting.com" className="footerLinks">
          <FontAwesomeIcon icon={faMailForward} /> info@benvironmentalconsulting.com
        </Link>
      </p>
        <div className="container">
          <div>
            <p
              style={{
                color: "#FFFFFF",
                marginBottom: "0rem",
              }}
            >
              &copy; 2022-{new Date().getFullYear()}, Benvironmental Consulting
              LLC
              <br />
              All Right Reserved |
              <Link to="/contact" className="footerLinks">
                <span> Contact</span> |{" "}
              </Link>
              <Link to="/terms">
                <span className="footerLinks"> Terms of Service |</span>
              </Link>
              <Link to="/privacypolicy">
                <span className="footerLinks"> Privacy Policy |</span>
              </Link>
              <a href="https://ubitek.io/" className="footerLinks">
                <span> Built By: UbiTek</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
